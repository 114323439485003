/* General styling */
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto Flex', sans-serif;
    background-color: #d7d7d7;
    color: white;
}

.private-policy-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 1000px;
    margin: 20px auto;
    background-color: #242424;
    color: white;
    box-sizing: border-box;
    border-radius: 15px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 1);
    overflow-y: auto;
}

/* Custom scrollbar styling */
.private-policy-page::-webkit-scrollbar {
    width: 12px;
}

.private-policy-page::-webkit-scrollbar-track {
    background: #2c2c2c;
    border-radius: 10px;
}

.private-policy-page::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
    border: 3px solid #2c2c2c;
}

.private-policy-page::-webkit-scrollbar-thumb:hover {
    background-color: #777;
}

.private-policy-container {
    background-color: #242424;
    padding: 20px;
    border-radius: 10px;
    color: #d7d7d7;
    box-sizing: border-box;
    width: 100%;
}

.language-selector {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.language-label {
    margin-right: 10px;
    font-weight: 500;
}

.private-policy-detail-container {
    background-color: #393939;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.private-policy-section {
    margin-bottom: 15px;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2c2c2c;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.section-header:hover {
    background-color: #3a3a3a;
}

.section-header h5 {
    margin: 0;
    font-size: 25px;
    font-weight: 250;
}

.section-header button {
    background-color: transparent;
    border: none;
    color: #d7d7d7;
    font-size: 18px;
    cursor: pointer;
}

.private-policy-content {
    padding: 15px;
    background-color: #2c2c2c;
    border-radius: 5px;
    margin-top: 10px;
}

.private-policy-content p {
    margin-bottom: 20px;
    font-weight: 400;
    line-height: 1.5;
}

.private-policy-content ul {
    padding-left: 20px;
}

.private-policy-content li {
    margin-bottom: 10px;
    list-style-type: disc;
}

.private-policy-label {
    font-family: 'Roboto Flex', sans-serif;
    font-weight: 300;
    margin-bottom: 10px;
    font-size: 18px;
    color: #d7d7d7;
}

.effective-date {
    font-family: 'Roboto Flex', sans-serif;
    font-weight: 300;
    margin-bottom: 10px;
    font-size: 16px;
    color: #d7d7d7;
}

/* Mobile responsive */
@media (max-width: 768px) {
    .private-policy-label {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .private-policy-content p {
        font-size: 14px;
    }

    .private-policy-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 0px;
    }

    .private-policy-page {
        margin-top: 0px;
        border-radius: 0px;
    }

    .private-policy-detail-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow-y: auto;
    }

    .section-header h5 {
        margin: 0;
        font-size: 15px;
        font-weight: 150;
    }
}

/* Tablet and larger screens */
@media (min-width: 1024px) {
    .private-policy-label {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 24px;
        color: #d7d7d7;
        text-align: center;
    }

    .private-policy-page {
        margin-bottom: 25px;
        border-top: 12px solid #1b1b1b;
    }

    .private-policy-content p {
        font-size: 18px;
    }
}





