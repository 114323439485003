* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.login-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #242424;
}

.banner-half {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

/* Login Half Styling */
.login-half {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #242424;
  background-size: cover;
  overflow: hidden;
}

.banner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  opacity: 0.85;
}

@media screen and (min-width: 1025px) {
  .login-page {
    flex-direction: row;
  }

  .banner-half {
    width: 50%;
    height: 100vh;
  }

  .login-half {
    width: 50%;
    height: 100vh;
  }
}

@media (max-width: 1024px) {
  .banner-half {
    height: 60vh;
  }
}

@media (max-width: 480px) {
  .banner-half {
    height: 40vh;
  }
  .login-half {
    flex: 1;
  }
}
