.race-bet-types {
  background-color: #333; /* Background color for the race bet types section */
  padding: 10px;
  display: flex;
  overflow-x: auto; /* Allow horizontal scrolling */
  white-space: nowrap; /* Prevent text wrapping */
}

.race-bet-types button {
  background-color: #383838; /* Button background color */
  color: #fff; /* Button text color */
  border: 1px solid #ffffff; /* White border */
  width: 82px; /* Fixed width */
  height: 37px; /* Fixed height */
  margin-left: 21px;
  margin-right: 8px; /* Margin for spacing between buttons */
  margin-bottom: 5px;
  border-radius: 30px; /* Rounded edges */
  cursor: pointer;
  font-size: 16px; /* Font size */
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease; /* Smooth background color transition */
  display: flex; /* To center the text */
  align-items: center; /* To center the text vertically */
  justify-content: center; /* To center the text horizontally */
  flex-shrink: 0; /* Prevent shrinking of buttons in mobile view */
}

/* Hover effect for buttons */
.bet-type-button.hovered {
  background: linear-gradient(135deg, #B61233, #420548); /* Hover background */
  color: #FFFFFF; /* Hover text color */
  border-color: #980E39; /* Hover border color */
}

/* Remove hover effect when not hovered */
.bet-type-button:not(.hovered):not(.active) {
  background-color: #383838; /* Default background color */
  color: #fff; /* Default text color */
  border-color: #ffffff; /* Default border color */
}

.race-bet-types button.active {
  background: linear-gradient(135deg, #B61233, #420548); /* Active background color */
  color: #fff; /* Active text color */
  border-color: #980E39; /* Active border color */
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .race-bet-types {
    overflow-x: auto; /* Enable horizontal scrolling on mobile view */
    padding: 10px 5px; /* Reduce padding for mobile */
  }

  .race-bet-types button {
    width: 82px; /* Keep a consistent width for buttons in mobile view */
    flex-shrink: 0; /* Ensure buttons don't shrink */
    margin-left: 10px;
    margin-right: 5px; /* Adjust margins for better spacing in mobile */
  }
}
