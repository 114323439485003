@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@400;500;700&display=swap');

body {
  background-color: #D7D7D7;
  margin: 0;
  padding: 0;
  font-family: 'Roboto Flex', sans-serif;
}

.gray-button {
  background-color: #979797;
  padding: 15px 30px;
  border: none;
  border-radius: 25px;
  color: #333;
  cursor: not-allowed;
  display: inline-block;
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-family: 'Roboto Flex', sans-serif;
  font-size: 17px;
}

.player-profile-page {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  background-color: #242424;
  padding: 20px;
  margin-top: -45px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.tabs-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: #d7d7d7;
  padding-left: 10px;
  padding-right: 10px;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
  margin-bottom: 45px;
  background-color: #d7d7d7;
  gap: 5px;
}

.warn-icon {
  width: 35px;
  height: 35px;
  margin-right: 15px; /* Space between icon and text */
  position: relative;
  margin-bottom: -30px; /* Adjust the vertical position */
  left: 10; /* Adjust the horizontal position */
}

.pass-note{
  text-align: left;
  margin-left: 45px;
}

.tab {
  padding: 10px 20px;
  border: none;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #D7D7D7;
  margin-bottom: -10px;
  color: #393939;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
}

.tab:not(:last-child) {
  margin-right: 5px;
}

.tab.active {
  background-color: #393939;
  color: #D7D7D7;
  border-bottom: 3px solid #BEF631;
}

.profile-method-container {
  position: relative;
  background-color: #242424;
  padding: 20px;
  border-radius: 10px;
  color: white;
  box-sizing: border-box;
  width: 100%;
  margin-top: 20px;
}

.profile-method-container h4.section-label {
  font-family: 'Roboto Flex', sans-serif;
  font-weight: 300;
  margin-bottom: 10px;
  font-size: 18px;
  color: white;
}

.profile-form-container, .password-container {
  margin-top: 30px;
  background-color: #393939;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input-group label {
  margin-top: 20px;
  font-weight: 250;
  font-size: 14px;
}

.input-group input,
.input-group textarea {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #323232;
  color: #979797;
  box-sizing: border-box;
  font-family: 'Roboto Flex', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

/* No need for edit icon styles since we removed edit functionality */

@media (min-width: 1024px) {
  .player-profile-page {
    padding: 40px;
  }

  .tabs {
    margin-top: 55px;
    margin-bottom: -20px;
  }

  .input-group input,
  .input-group textarea {
    height: 50px;
  }

  .outer-container{
    min-height: 100vh;
    background-color: transparent;
  }

  .tab{
    margin-bottom: 35px;
  }
}

@media (max-width: 768px) {
  .player-profile-page {
    width: 100%;
    max-width: none;
    padding: 10px;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
  }

  .profile-method-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .tabs-container {
    margin-top: -5px;
  }

  .tab{
    height: 30px;
    padding-bottom: 20px;
    font-size: 12px;
  }

  .tabs{
    border: 1px solid transparent;
  }

  .warn-icon {
    margin-right: 15px; /* Space between icon and text */
    margin-bottom: -40px; /* Adjust the vertical position */
    left: 10; /* Adjust the horizontal position */
  }
}
