.copy-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-popup-content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}