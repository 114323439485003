.header-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 29px; /* Adjust the height as needed */
  overflow: hidden;
}

.balance-section {
  background-color: #383838; /* Dark background for balance label */
  color: #FFFFFF;
  padding: 0 10px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0 0, 100% 0, calc(100% - 20px) 100%, 0 100%); /* Increase overlap */
  height: 29px;
  margin-left: -10px; /* Adjust margin to create overlap */
  margin-right: -10px; /* Adjust margin to create overlap */
  font-family: 'Roboto Flex', sans-serif; /* Apply Roboto Flex */
  font-size: 12px;
}

.amount-section {
  background: linear-gradient(135deg, #FFF61F 0%, #80F441 100%);
  color: #000000;
  padding: 0 10px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  width: 92px;
  clip-path: polygon(20px 0, 100% 0, calc(100% - 20px) 100%, 0 100%); /* Increase overlap */
  margin-left: -10px; /* Adjust margin to create overlap */
  margin-right: -10px; /* Adjust margin to create overlap */
  font-weight: bold; /* Make the text bold */
  font-size: 12px;
  font-family: 'Roboto Flex', sans-serif; /* Apply Roboto Flex */
  font-weight: bold; /* Make the text bold */
}

.action-buttons-section {
  background: linear-gradient(135deg, #B61233, #420548); /* Apply the gradient background */
  color: #fff;
  padding: 0 10px;
  flex: 2; /* Wider section */
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content */
  gap: 7px; /* Further reduce the gap between the CTAs */
  height: 29px;
  clip-path: polygon(20px 0, 100% 0, 100% 100%, 0 100%);
  margin-left: -10px; /* Adjust margin to create overlap */
}

.chat-btn, .telegram-btn  {
  height: 21px;
  width: auto;
  cursor: pointer;
  background-color: transparent; /* Remove background */
  border: none; /* Remove border */
  margin-top: 3px;
}

.text-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: #383838;
  color: #FFFFFF;
  padding: 0 10px;
  height: 29px;
  font-family: 'Roboto Flex', sans-serif;
  font-weight: bold;
  font-size: 12px;
  clip-path: polygon(0 0, 100% 0, calc(100% - 20px) 100%, 0 100%);
  margin-left: -10px;
  margin-right: -10px;
}

.back-icon {
  width: 30px; /* Adjust the size of the back icon */
  height: auto;
  margin-right: 8px; /* Add some space between the icon and the text */
  cursor: pointer;
}
