/* General styling */
body {
    margin: 0;
    height: 100%;
    padding: 0;
    font-family: 'Roboto Flex', sans-serif;
    background: linear-gradient(135deg, #3E424B, #808588);
    color: white;
}

.maintenance-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1850px;
    margin: 0 auto;
    background-color: #242424;
    color: white;
    box-sizing: border-box;
    margin-top: -5px;
    width: 100%;
    height: 830px;
    border-radius: 0;
    padding: 20px;
    text-align: center; /* Centers text content */
}

.maintenance-image {
    max-width: 400px; /* Adjust size as needed */
    height: auto;
    margin-bottom: 20px;
    width:400px;
}

h1 {
    font-size: 2rem;
    margin: 10px 0;
}

.maintenance-message {
    font-size: 14px; /* Larger font for the first message */
    margin: 10px 0;
}

.return-message {
    font-size: 16px; /* Smaller font for the second message */
    margin: 5px 0;
}

@media (max-width: 768px) {
    .maintenance-image {
        width:200px;
    }
    
    .maintenance-page{
        width: 100%;
        max-width: none;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 912px), (max-width: 1024px), (max-width: 825px) {
    .maintenance-page{
        width: 100%;
        max-width: none;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
}


