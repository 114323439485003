.footer-container {
    background: linear-gradient(135deg, #383838, #3A3A3A); /* Gradient background */
    border-top: 2px solid #BDB7AB;
    color: #D7D7D7; /* Light gray text color */
    margin-top:70px;
    text-align: center;
    padding: 20px 0;
    flex-shrink: 0; /* Prevent the footer from shrinking */
    position: relative;
}

.footer-links {
    margin-bottom: 10px;
}

.footer-link {
    background: none; /* Remove default button background */
    border: none; /* Remove default button border */
    color: #D7D7D7;
    margin: 0 15px;
    text-decoration: none;
    font-size: 1.1em;
    transition: color 0.3s ease;
    cursor: pointer; /* Make it look clickable */
    display: inline-block; /* Make them align like inline elements */
}

.footer-link:hover {
    color: #FFD700; /* Optional hover effect to gold */
}

.footer-link:focus {
    outline: none; /* Remove default button focus outline */
}

.footer-copyright {
    font-size: 0.9em;
    color: #D7D7D7; /* Light gray text color */
}

.footer-container p {
    margin: 0;
}
