.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Dark background overlay */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #383838 transparent;
    padding: 15px;
    border-radius: 8px;
    width: 90%; /* Adjust width based on screen size */
    max-width: 900px; /* Maximum width */
    max-height: 90%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .video-container {
    width: 100%;
    height: auto;
  }
  
  .close-button {
    position: absolute;
    top: -7px;
    right: 10px;
    width: 30px;
  }
  
  video {
    margin-top: 20px;
    width: 100%; /* Make video responsive */
    height: auto;
  }
  