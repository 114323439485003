/* TopNavigation.css */
.navbar {
    background-color: #383838;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #D7D7D7;
    z-index: 5;
}

.logo {
    display: flex;
    align-items: center;
}

.logo-image {
    width: 400px;
    height: 50px;
}

.nav-actions {
    background: linear-gradient(135deg, #B61233, #420548);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding: 0 20px;
    gap: 20px;
    height: 60px;
    clip-path: polygon(40px 0, 100% 0, 100% 100%, 0 100%);
}

.nav-links {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex: 1;
}

.nav-link {
    color: #D7D7D7;
    font-family: 'Roboto Flex', sans-serif;
    font-size: 1.1em;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    transition: color 0.3s ease;
}

.nav-link:hover {
    color: #D7D7D7;
}

/* Active link style */
.nav-link.active {
    color: #D7D7D7;
    border-bottom: 2px solid #D7D7D7; /* Underline the active link */
}

/* Right Side Items */
.nav-items {
    display: flex;
    align-items: center;
    gap: 20px;
    white-space: nowrap;
    z-index: 5;
}

.user-info, .balance-info {
    font-size: 1em;
    font-family: 'Roboto Flex', sans-serif;
}

.user-name, .balance-amount {
    color: #BEF631;
    font-weight: bold;
    font-family: 'Roboto Flex', sans-serif;
}

.nav-icon {
    font-size: 1.5em;
    cursor: pointer;
}

.refresh-icon:hover, .user-icon:hover {
    color: #D7D7D7;
}

.account-dropdown {
    position: relative;
}

.account-dropdown-menu {
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: #393939;
    color: #D7D7D7;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
}

.dropdown-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
}

.dropdown-item:hover {
    background-color: #323232;
}

.dropdown-divider {
    border: none;
    border-top: 1px solid #525252;
    margin: 5px 0;
}

.logout {
    color: #BEF631;
    font-weight: bold;
}

.dropdown-icon {
    width: 20px;
    height: 20px;
}

.nav-actions.no-active-event .nav-links {
  display: none; /* Hide nav links when no active event */
}

.nav-actions.no-active-event .user-info{
  margin-left: 850px;
}
