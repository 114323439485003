.race-details-container {
  background-image: url('../../../../assets/Race/RaceEventBackground.png');
  background-size: cover; /* Ensure the image covers the entire container */
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  display: flex;
  justify-content: flex-start; /* Align content to the left */
  align-items: center;
  text-align: left; /* Ensure text is left-aligned */
  margin-top: -3.3px;
}

  .race-details-text.first {
    color: #FFFFFF; /* White color */
    margin-bottom: 5px;
    font-size: 11px;
    font-family: 'Lato', sans-serif;
    text-align: left;
    text-transform: uppercase; /* Make text all uppercase */
  }
  
  .race-details-text.second {
    color: #FFCF24; /* Yellow color */
    font-size: 11px;
    font-weight: regular;
    font-family: 'Lato', sans-serif;
    text-align: left;
  }
  