.bottom-nav {
  background: linear-gradient(135deg, #AD1134, #5C0843);
  color: white;
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 47px;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  padding: 0 5px;
  /* Ensure consistent width and height across all states */
  width: 60px; /* Fixed width to prevent layout shifts */
  height: 47px; /* Height of the bottom navigation bar */
}

.nav-icon {
  margin-bottom: -1px; /* Space between icon and text */
  /* Ensure consistent icon size */
  width: auto;
  height: 35px; /* Set a consistent height for all icons */
}

.nav-item.active {
  background-color: rgba(0, 0, 0, 0.2); /* Apply the active background */
  /* Keep the size consistent with the default state */
  width: 60px;
  height: 47px;
}

.nav-item:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Slightly lighter for hover */
  /* Keep the size consistent with the default state */
  width: 60px;
  height: 47px;
}

/* Individual icon sizing */
.race .nav-icon {
  width: 29px;
  height: 35px;
}

.payment .nav-icon {
  width: 41px;
  height: 35px;
}

.transaction .nav-icon {
  width: 55px;
  height: 35px;
}

.result .nav-icon {
  width: 29px;
  height: 35px;
}

.account .nav-icon {
  width: 39px;
  height: 35px;
}

.more-games .nav-icon {
  width: 55px;
  height: 35px;
}

.nav-icon{
  margin-top: 5px;
}
