.race-closed-betting {
  background-image: url('../../../../../assets/Race/RaceEventClosedBetting.png');
  background-size: cover; /* Ensure the image covers the entire container */
  background-repeat: no-repeat;
  padding: 30px;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  display: flex;
  justify-content: flex-start; /* Align content to the left */
  align-items: center;
  text-align: left; /* Ensure text is left-aligned */
  margin-top: -3.3px;
}

@media(min-width:1024px){
  .race-closed-betting{
    height:180px;
  }
}

@media(width:768px){
  .race-closed-betting{
    height:110px;
  }
}