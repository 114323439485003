.results-container {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    background-color: #242424;
    padding: 20px;
    margin-top: 100px;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 15px;
    flex-direction: column;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 1);
  }
  
  .filter-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .filter-dropdown {
    margin-right: 10px;
    padding: 5px;
    border-radius: 5px;
    border: none;
  }
  
  .view-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

.results-container h2{
  color: #d7d7d7;
  margin-bottom: 10px;
}
  
  .results-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .results-table th, .results-table td {
    padding: 10px;
    border: 1px solid #d7d7d7;
    text-align: left;
  }

  .result-circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    margin: 0 3px;
    background: linear-gradient(90deg, #C9F62E 0%, #96F53C 72%, #82F441 100%);
    color: #393939;
    border-radius: 50%;
    font-size: 1em;
    font-weight: bold;
  }
  
@media (max-width: 768px){
    .results-container {
        margin-top: -10px;
        min-height: 100vh;
      }
    
    .result-circle{
      width: 15px;
      height: 15px;
      margin-left: -1px;
      font-size: 0.8em;
    }
      
}

@media (min-width: 1024px) {
  .results-container {
    border-radius: 15px;
    max-height: 800px;
  }
  .results-content-container{
    min-height: 100vh;
  }
}  

@media (max-width: 390px) {
  .result-circle {
    width: 18px; /* Smaller width for very small screens */
    height: 18px; /* Smaller height for very small screens */
    font-size: 0.7em;
  }
}

@media (max-width: 375px), (max-width: 360px), (max-width: 344px) {
  .result-circle {
    width: 11px; /* Even smaller width */
    height: 11px; /* Even smaller height */
    font-size: 0.7em;
  }
}
