.bet-modal {
  background-color: rgba(85, 85, 85, 0.5);
  padding: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
  max-width: 600px;
  height: 150px;
  margin: auto;
  color: white;
  margin-bottom: 45px;
}

.bet-inputs {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

/* WebKit browsers */
.bet-inputs::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of the scrollbar */
}

.bet-inputs::-webkit-scrollbar-thumb {
  background-color: transparent; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.bet-inputs::-webkit-scrollbar-track {
  background: transparent; /* Color of the scrollbar track */
}

.bet-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bet-input-label {
  margin-top: 1px;
  margin-left: 3px;
  margin-bottom: 5px;
  color: white;
  font-size: 12px;
}

.bet-input {
  min-width: 25px; /* Initial width */
  height: 25px; /* Fixed height */
  padding: 8px;
  border: 1px solid #7AA214;
  background-color: #FFFFFF;
  color: #242424;
  text-align: center;
  overflow: hidden; /* Hide overflow text */
  white-space: nowrap; /* Prevent text wrapping */
  transition: width 0.3s ease; /* Smooth transition for width change */
}

.confirm-modal-button {
  background-color: #4CAF50;
  color: white;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  height: 30px;
  margin-left: 5px;
  margin-top: 10px;
}

.confirm-modal-button:hover {
  background-color: #45a049;
}

.confirm-modal-button.disabled {
  background-color: gray;
  cursor: not-allowed;
}

.bet-modal-title {
  font-size: 16px;
  font-weight: 200;
  color: white;
  margin-bottom: 5px;
}

.initial-bet-input {
  width:70px;
  height: 30px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #D7D7D7;
  color: #242424;
  text-align: center;
  font-size: 16px;
  box-sizing: border-box;
}

.initial-bet-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.initial-bet-input:focus {
  outline: none;
  border-color: #4CAF50;
}

.insufficient-balance-note {
  margin-top: -5px;
  font-size: 11px;
  background: linear-gradient(90deg, #c9f62e 0%, #96f53c 72%, #82f441 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  text-shadow: 0 0 2px rgba(130, 244, 65, 0.5), 0 0 4px rgba(150, 245, 60, 0.4), 0 0 6px rgba(201, 246, 46, 0.3);
}

.bet-amount-input {
  width: 100%; /* Full width for better usability */
  max-width: 140px; /* Limit max width for consistency */
  height: 25px; /* Slightly taller for better touch targets */
  padding: 8px 12px; /* Adequate padding for comfort */
  margin-top: 10px; /* Space from other elements */
  margin-bottom: 10px; /* Space from other elements */
  border: 1px solid #ccc; /* Subtle border */
  border-radius: 5px; /* Rounded corners for modern look */
  background-color: rgba(255, 255, 255, 0.15); /* Slightly transparent for depth */
  color: white; /* Text color for readability */
  font-size: 16px; /* Larger font for readability */
  box-sizing: border-box; /* Include padding in width/height */
  transition: border-color 0.3s ease; /* Smooth transition for focus */
}

.bet-amount-input::placeholder {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5); /* Placeholder color for hinting */
}

.bet-amount-input:focus {
  outline: none; /* Remove default outline */
  border-color: #4CAF50; /* Green border on focus for feedback */
  background-color: rgba(255, 255, 255, 0.25); /* Slightly lighter background on focus */
}

.multiplier-input {
  width: 115px; /* Full width for better usability */
  max-width: 200px; /* Limit max width for consistency */
  height: 25px; /* Slightly taller for better touch targets */
  padding: 8px 2px; /* Adequate padding for comfort */
  margin-top: 10px; /* Space from other elements */
  margin-bottom: 10px; /* Space from other elements */
  border: 1px solid #ccc; /* Subtle border */
  border-radius: 5px; /* Rounded corners for modern look */
  background-color: rgba(255, 255, 255, 0.15); /* Slightly transparent for depth */
  color: white; /* Text color for readability */
  font-size: 16px; /* Larger font for readability */
  box-sizing: border-box; /* Include padding in width/height */
  transition: border-color 0.3s ease; /* Smooth transition for focus */
}

.multiplier-input::placeholder {
  color: rgba(255, 255, 255, 0.5); /* Placeholder color for hinting */
}

.multiplier-input:focus {
  outline: none; /* Remove default outline */
  border-color: #4CAF50; /* Green border on focus for feedback */
  background-color: rgba(255, 255, 255, 0.25); /* Slightly lighter background on focus */
}
