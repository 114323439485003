.header-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 29px;
    overflow: hidden;
  }
  
  /* Styling for the replaceable text section */
  .text-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    background-color: #383838;
    color: #FFFFFF;
    padding: 0 10px;
    height: 29px;
    font-family: 'Roboto Flex', sans-serif;
    font-weight: bold;
    font-size: 12px;
    clip-path: polygon(0 0, 100% 0, calc(100% - 20px) 100%, 0 100%);
    margin-left: -10px;
    margin-right: -10px;
  }
  
  .back-icon {
    width: 30px; /* Adjust the size of the back icon */
    height: auto;
    margin-right: 8px; /* Add some space between the icon and the text */
    cursor: pointer;
  }
  
  .action-buttons-section {
    background: linear-gradient(135deg, #B61233, #420548);
    color: #fff;
    padding: 0 10px;
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    height: 29px;
    clip-path: polygon(20px 0, 100% 0, 100% 100%, 0 100%);
    margin-left: -10px;
  }
  
  .b-chat-btn, .b-how-to-bet-btn {
    height: 21px;
    width: auto;
    cursor: pointer;
    background-color: transparent;
    border: none;
    margin-top: 3px;
  }


 @media (min-width: 1200px){
    .b-how-to-bet-btn{
        margin-right: 800px ;
    }

 } 