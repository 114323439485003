/* src/components/Banner.css */

.banner-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #070605; /* Fallback background color */
}

.banner-image {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .banner-image {
    margin-top: 90px;
    width: 100%;
  }
}

/* Media Queries for Responsiveness */
/* @media (max-width: 768px) {
  .banner-container {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .banner-container {
    height: 100vh;
  }
} */
