@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@400;500;700&display=swap');

body {
  background-color: #D7D7D7;
  margin: 0;
  padding: 0;
  font-family: 'Roboto Flex', sans-serif;
}

.transaction-container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  background-color: #242424;
  padding: 20px;
  margin-top: -48px;
  margin-bottom: 20px;
  box-sizing: border-box;
  height: 500px; /* Set a fixed height */
}


.transaction-tabs-container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #d7d7d7;
}

.transaction-tabs {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
  margin-bottom: 48px;
  background-color: #d7d7d7;
  gap: 5px;
}

.transaction-tab {
  padding: 10px 20px;
  border: none;
  background-color: #D7D7D7;
  color: #393939;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  flex-grow: 1; /* Make the tabs fill the container */
  margin-bottom: -7px;
  max-width: 800px; /* Optional: set a maximum width to avoid very wide tabs */
}

.transaction-tab.active {
  background-color: #393939;
  color: #D7D7D7;
  border-bottom: 3px solid #BEF631;
}


.transaction-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.transaction-table th, 
.transaction-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.transaction-table th {
  background-color: #242424;
  color: #D7D7D7;
}

.transaction-table tr:nth-child(even) {
  background-color: #393939;
}

.transaction-table tr:nth-child(odd) {
  background-color: #2E2E2E;
}

.transaction-table tr:hover {
  background-color: #575757;
}

.transaction-details {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  font-size: 14px;
}

.transaction-details-left,
.transaction-details-right {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.transaction-details-left {
  border-right: 1px solid #4C4C4C; /* Add border between sections */
  padding-right: 10px;
  margin-right: 450px; /* Ensure space between border and right section */
}

.transaction-details-right {
  padding-left: 10px;
}

.transaction-details-left div,
.transaction-details-right div {
  margin-bottom: 5px;
}

.transaction-details-right div {
  margin-top: -40px;
  margin-right: 250px;
  text-align: right;
}

.transaction-details-left div {
  margin-left: 30px;
}

.transac-details-item{
  font-size: 12px;
  margin-left: 55px;
}

.from-gcash,
.sent-to{
  color: #A2A2A2;
  font-weight: 20;
}

.bet-container {
  width: 100%;
  max-width: 1100px;
  background-color: #242424;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: auto; /* Ensures the table is scrollable on smaller screens */
  overflow-y: auto;
}

.bet-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto; /* Change to auto for flexible column widths */
}

.bet-table th:nth-child(2), 
.bet-table td:nth-child(2) {
  width: 40%; /* Adjust this percentage to make the column wider */
}

.bet-table th, 
.bet-table td {
  padding: 8px;
  font-size: 0.8em;
  background-color: #313131;
  text-align: left;
  overflow: hidden;
  white-space: normal; /* Allow text to wrap */
  text-overflow: ellipsis;
  word-wrap: break-word; /* Ensure long words break */
}

.bet-table th {
  color: #A2A2A2;
  font-weight: lighter;
  font-size: 10px;
}

.bet-line {
  display: flex;
  flex-wrap: wrap; /* Allows numbers to wrap to the next line */
  gap: 5px;
  margin-top: 5px;
}

.bet-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-bottom: 7px;
  margin-right: 3px;
  background: linear-gradient(90deg, #C9F62E 0%, #96F53C 72%, #82F441 100%);
  color: #393939;
  border-radius: 50%;
  font-size: 1em;
  font-weight: bold;
}

.bet-transaction {
  background-color: #242424;
  padding: 30px;
  padding-bottom: 25px;
  border-radius: 8px;
}

.bet-header {
  height: 25px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  background-color: #797979;
  color: #d7d7d7;
}

.bet-status {
  font-weight: bold;
  font-size: 12px;
}

.bet-status.win {
  color: #bef631;
}

.bet-status.lose {
  color: #FF8989;
}

.bet-status.refund {
  color: #78A3FF;
}

.bet-balance {
  height: 50px;
  padding: 10px;
  border-top: 1px solid #4C4C4C;
  background-color: #313131;
  font-size: 12px;
  text-align: right;
  color: #d7d7d7;
  margin-bottom: -30px;
}

.bet-amount-computation {
  background-color: #313131;
  font-size: 12px;
  text-align: right;
  color: #d7d7d7;
  margin-bottom: 30px;
}

.bet-date, 
.bet-ticket{
  color: #FFFFFF;
  font-size: 10px;
}

.bet-ticket {
  border: 1px solid #ADADAD; /* Add a white border */
  border-radius: 12px; /* Adjust the border radius */
  height: 16px;
  padding: 1px 8px; /* Add some padding */
}

.bet-ticket strong {
  font-weight: bold;
}

.bet-table th{
  font-size: 14px;
}

.bet-status {
  font-size: 14px;
}

.filter-container {
  margin-top: 8px;
  margin-left: 5px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-dropdown {
  padding: 10px;
  background-color: #393939;
  color: #D7D7D7;
  border: 1px solid #242424;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

.filter-dropdown:focus {
  border-color: #BEF631;
}

.transac-amount {
  height: 30px;
  padding: 10px;
  border-top: 1px solid #4C4C4C;
  background-color: #313131;
  font-size: 12px;
  text-align: right;
  color: #d7d7d7;
}

.transac-id{
  margin-top: 10px;
  margin-left: 25px;
  margin-bottom: 20px;
  border: 1px solid #ADADAD; /* Add a white border */
  border-radius: 12px; /* Adjust the border radius */
  height: 20px;
  width: 380px;
  padding: 1px 8px;
}

.transaction-list {
  margin-left: 5px;
  margin-right: 5px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.transaction-item {
  background-color: #313131;
  color: #d7d7d7;
}

.transaction-header {
  height: 25px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  background-color: #797979;
  color: #d7d7d7;
}

.transaction-date {
  margin-left: 20px;
  font-size: 12px;
}

.status-value {
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 12px;
}

.status-label {
  margin-bottom: 10px;
  font-size: 10px;
  margin-right: 13px;
}

.transaction-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 10px;
}

.remarks-label {
  color: #A2A2A2;
  font-weight: 20;
  margin-left: 30px;
}

.remarks-value {
  color: #D7D7D7;
  font-weight: 20;
  margin-left: 30px;
}

.transac-type{
  font-weight: bold;
  font-size: 17px;
}

@media (min-width: 1024px) {

  .transaction-container {
    padding: 40px;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
  }

  .transaction-tab{
    margin-bottom: -9px;
  }

  .bet-date, 
  .bet-ticket{
    color: #FFFFFF;
    font-size: 12px;
  }
}


@media (max-width: 768px) {

  .transaction-table th,
  .transaction-table td {
    padding: 8px;
  }

  .transaction-container {
    width: 100%;
    max-width: none;
    padding: 1px;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
  }

  .transaction-tabs-container {
    margin-top: -5px;
  }

  .transaction-tab{
    margin-bottom: -9px;
  }

  .transaction-details {
    flex-direction: column;
  }

  .transaction-table th, .transaction-table td {
    font-size: 0.8em;
  }

  .transaction-details-top,
  .transaction-details-bottom {
    word-break: break-word;
  }

  .bet-container {
    padding: 1px;
  }

  .transaction-tab{
    height: 30px;
    padding-bottom: 20px;
  }

  .bet-circle {
    width: 15px;
    height: 15px;
    margin-left: -5px;
    font-size: 0.8em;
  }

  .bet-date{
    margin-top: 7px;
  }

  .bet-ticket{
    width: 165px;
    height: 20px;
    font-size: 8px;
    padding-bottom: 11px;
    text-align: center;
    margin-top: -3px;
    margin-bottom: 25px;
  }

  .transac-id{
    font-size: 7.5px;
    /* height: 15px; */
    max-height: 95px;
    width: 105px;
  }

  .bet-container {
    max-height: 500px;
  }

  .transaction-details-left {
    border-right: 1px solid #4C4C4C; /* Add border between sections */
    padding-right: 10px;
    margin-right: 160px; /* Ensure space between border and right section */
  }

  .transaction-details-right div {
    margin-top: -55px;
    margin-right: 60px;
    text-align: right;
  }
}

@media (width: 360px){
  .transaction-details-left {
    margin-right: 135px; /* Ensure space between border and right section */
  }

  .transaction-details-right div {
    margin-right: 35px;
}
}

@media (width: 375px) and (height: 667px){
  .transaction-details-left {
    margin-right: 130px; /* Ensure space between border and right section */
  }

  .transaction-details-right div {
    margin-right: 20px;
  }

  .bet-balance{
    margin-bottom: 5px;
  }

  .transaction-item{
    margin-bottom: 20px;
  }

  .bet-ticket{
    width: 165px;
    height: 20px;
    font-size: 8px;
    padding-bottom: 11px;
    text-align: center;
    margin-top: -3px;
  }

  .transaction-container{
    padding: 10px;
    margin-bottom: 46px;
  }

  .wallet-pagination{
    margin-top: -100px;
  }
}

@media (width: 414px){  
  .transaction-details-right div {
    margin-right: 20px;
}
  .bet-container{
    max-height: 2200px;
  }
}

@media (width: 390px){  
  .transaction-details-right div {
    margin-right: 35px;
}
}

@media (width: 430px){  
  .transaction-details-right div {
    margin-right: 35px;
}
}

@media (width: 540px){  
  .transaction-details-right div {
    margin-right: 35px;
}
  .transaction-item{
    margin-bottom: 25px;
  }
}

@media (width: 768px){
  .transaction-details-left {
    margin-right: 350px; /* Ensure space between border and right section */
  }

  .transaction-details-right div {
    margin-right: 200px;
  }
}

.transaction-list::-webkit-scrollbar,
.bet-container::-webkit-scrollbar {
  width: 0; /* Remove scrollbar width */
}

.transaction-list,
.bet-container {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.transaction-list::-webkit-scrollbar-track,
.bet-container::-webkit-scrollbar-track {
  background: transparent; /* Transparent track */
}

.transaction-list::-webkit-scrollbar-thumb,
.bet-container::-webkit-scrollbar-thumb {
  background: transparent; /* Transparent thumb */
}

.transaction-list, .bet-container {
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: auto; /* For Internet Explorer 10+ */
}

.transaction-list {
  max-height: 600px;
}

.bet-container {
  max-height: 650px;
}

.transaction-list::-webkit-scrollbar, .bet-container::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.transaction-list::-webkit-scrollbar-thumb, .bet-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
}

.transaction-list::-webkit-scrollbar-track, .bet-container::-webkit-scrollbar-track {
  background: #242424; /* Color of the scrollbar track */
}

.wallet-pagination{
  margin-top: 20px;
  margin-bottom: 20px;
}

.system-adjustment {
  font-weight: bold;
  font-size: 15px;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  white-space: pre-line; /* Allows text to wrap based on newlines */
  line-height: 1.2; /* Adjust line height for better spacing */
}

/* Optional: Add specific styles for remarks related to System Adjustment */
.system-adjustment-remarks {
  color: #FF5733; /* Match the color with the type */
  font-style: italic;
  margin-left: 30px;
}

/* Ensure to apply this class in JSX where remarks are displayed */





