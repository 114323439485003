/* General styling */
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto Flex', sans-serif;
    background-color: #d7d7d7;
    color: white;
}

.betting-rules-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 1200px; /* Adjust for larger screens */
    margin: 0 auto;
    background-color: #242424;
    color: white;
    box-sizing: border-box;
    margin-top: 40px;
    width: 100%;
    border-radius: 15px;
    position: relative;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 1);
}

.betting-rules-container {
    background-color: #242424;
    padding: 20px;
    border-radius: 10px;
    color: white;
    box-sizing: border-box;
    width: 100%;
}

.betting-rules-container h4.label {
    font-family: 'Roboto Flex', sans-serif;
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 20px;
    color: #d7d7d7;
}

.language-selector {
    margin-bottom: 20px;
}

.language-selector select {
    background-color: #393939;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-family: 'Roboto Flex', sans-serif;
    font-size: 16px;
    cursor: pointer;
    outline: none;
}

.language-label {
    color: #d7d7d7;
    font-family: 'Roboto Flex', sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
}

.language-selector select:focus {
    border: 1px solid #d7d7d7;
}

.rules-detail-container {
    background-color: #393939;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.rules-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.rules-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
    font-family: 'Roboto Flex', sans-serif;
}

.rules-content p {
    margin-bottom: 5px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
}

.rules-content p strong {
    font-weight: 700;
}

.pool-types-container {
    margin-top: 20px;
}

.pool-types-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.pool-types-table th, .pool-types-table td {
    border: 1px solid #d7d7d7;
    padding: 8px;
    text-align: left;
    color: white;
}

.pool-types-table th {
    background-color: #393939;
    font-weight: bold;
}

.pool-types-table td {
    background-color: #242424;
}

/* Mobile responsive */
@media (max-width: 768px) {

    .betting-rules-container h4.label {
        margin-top: 5px;
        margin-left: 5px;
        font-weight: bold;
        font-size: 16px;
    }

    .rules-content p {
        font-size: 14px;
    }

    .rules-detail-container {
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;
    }

    .betting-rules-page {
        margin-top: 20px;
    }

    .betting-rules-container {
        flex-grow: 1;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 0px;
        margin-top: -21px;
        margin-bottom: 30px;
    }

    .pool-types-table th, .pool-types-table td {
        font-size: 12px;
        padding: 6px;
    }
}

/* Tablet and larger screens */
@media (min-width: 1024px) {

    .betting-rules-container h4.label {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 24px;
    }

    .betting-rules-page {
        margin-bottom: 25px;
        border-top: 12px solid #1b1b1b;
    }

    .rules-content p {
        margin-top: 10px;
        font-size: 16px;
    }
}

/* Disable text selection */
.no-select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}



