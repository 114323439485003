@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@400;500;700&display=swap");

body {
  background-color: #d7d7d7;
  margin: 0;
  padding: 0;
  font-family: "Roboto Flex", sans-serif;
}

.payment-method-page {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  background-color: #242424;
  padding: 20px;
  margin-top: -50px;
  box-sizing: border-box;
  margin-bottom: 20px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 1);
}

.payment-tabs-container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: #d7d7d7;
  padding-left: 10px;
  padding-right: 10px;
}

.payment-tabs {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
  margin-bottom: 48px;
  background-color: #d7d7d7;
  gap: 5px;
}

.payment-tab {
  padding: 10px 20px;
  border: none;
  background-color: #d7d7d7;
  color: #393939;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  flex-grow: 1;
  margin-bottom: -7px;
  max-width: 800px;
}

.payment-tab.active {
  background-color: #393939;
  color: #d7d7d7;
  border-bottom: 3px solid #bef631;
}

.payment-method-container {
  background-color: #242424;
  padding: 20px;
  border-radius: 10px;
  color: white;
  box-sizing: border-box;
  width: 100%;
}

.payment-method-container h4.section-label {
  font-family: "Roboto Flex", sans-serif;
  font-weight: 300;
  margin-bottom: 10px;
  font-size: 18px;
  color: white;
}

.deposit-details-container,
.deposit-form-container,
.withdraw-details-container {
  background-color: #393939;
  padding: 15px;
  padding-bottom: 2px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.withdraw-details-container {
  margin-top: 10px;
}

.deposit-details,
.withdraw-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.withdraw-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #0f2bb2;
  border-radius: 5px;
  font-family: "Roboto Flex", sans-serif;
}

.withdraw-item p {
  margin: 0;
  font-weight: 400;
}

.deposit-item {
  font-size: 14px;
  margin: 0;
  font-weight: 400;
}

.deposit-item p strong,
.withdraw-item p strong {
  font-weight: 700;
}

.note {
  margin-top: 2px;
  background-color: #980e39;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Roboto Flex", sans-serif;
}

.note strong {
  font-weight: 700;
}

.note-list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  font-family: "Roboto Flex", sans-serif;
  font-weight: 400;
}

.note-list li {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}

.check-icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.payment-method-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 25px;
  margin-top: 10px;
  margin-bottom: 5px;
  gap: 10px;
  flex-wrap: wrap;
}

.payment-method-icons img {
  width: calc(100% / 7 - 10px);
  max-width: 50px;
  height: auto;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.payment-method-icons img.selected {
  border: 3px solid #bef631;
}

.deposit-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #0f2bb2;
  border-radius: 5px;
  font-family: "Roboto Flex", sans-serif;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
  position: relative; /* Ensure this is set */
}

.copy-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 3px;
  right: 10px; /* Adjust as needed */
  cursor: pointer;
}

.copy-icon {
  width: 24px; /* Adjust size as needed */
  height: 24px;
}

.copy-caption {
  font-size: 7px;
  color: #fff;
  margin-top: 4px; /* Space between icon and caption */
  text-align: center;
}

.amount-button.selected {
  border: 3px solid #242424;
}

.withdrawal-note {
  font-size: 14px;
}

.deposit-note{
  font-size: 14px;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input-group label {
  font-weight: 500;
}

.input-group input,
.input-group textarea {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #323232;
  /* color: white; */
  box-sizing: border-box;
  font-family: "Roboto Flex", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.input-group input::placeholder,
.input-group textarea::placeholder {
  color: #888888;
}

.input-group input:focus,
.input-group textarea:focus {
  outline: none;
  color: white;
  background-color: #323232;
}

.amount-buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.amount-button {
  background: linear-gradient(90deg, #c9f62e 0%, #96f53c 72%, #82f441 100%);
  color: black;
  border: none;
  padding: 10px;
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  font-size: 14px;
}

.amount-button:hover {
  background: linear-gradient(90deg, #82f441 0%, #96f53c 72%, #c9f62e 100%);
  color: black;
}

.warning-message{
  font-size: 11px;
  background: linear-gradient(90deg, #c9f62e 0%, #96f53c 72%, #82f441 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  text-shadow: 0 0 1px rgba(130, 244, 65, 0.5), 0 0 3px rgba(150, 245, 60, 0.4), 0 0 5px rgba(201, 246, 46, 0.3);
}

@media (min-width: 1024px) {
  .payment-method-icons img {
    width: calc(100% / 7 - 10px);
    max-width: 70px;
    height: auto;
  }

  .payment-method-icons {
    justify-content: space-between;
  }

  .payment-method-page {
    padding: 40px;
  }
}

@media (max-width: 768px) {
  .payment-method-page {
    width: 100%;
    max-width: none;
    padding: 10px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .payment-tabs-container {
    margin-top: -5px;
  }

  .payment-method-container {
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  .payment-button {
    margin-top: auto;
  }

  .payment-tab {
    margin-bottom: -6px;
    height: 30px;
    padding-bottom: 20px;
  }

  .deposit-item p {
    font-size: 14px; /* Smaller font size for tablets */
  }

  .payment-method-icons img {
    width: calc(120% / 4 - 10px); /* Further increase size for smaller screens */
    max-width: 65px; /* Further increase max-width */
    height: auto;
  }
}

@media (width:360px){
  .copy-caption{
    font-size: 5px;
  }

  .copy-icon{
    width: 15px; /* Adjust size as needed */
    height: 15px;
  }

  .copy-icon-container{
    bottom: 10px;
    right: 5px;
  }

  .payment-method-icons {
    margin-left: 40px;
  }

  .payment-method-icons img {
    width: calc(120% / 4 - 10px); /* Further increase size for smaller screens */
    max-width: 65px; /* Further increase max-width */
    height: auto;
    margin-right: 10px;
  }
}

@media (max-width: 480px) {
  .deposit-item p {
    font-size: 12px; /* Even smaller font size for mobile phones */
  }

  .payment-method-icons img {
    width: calc(120% / 4 - 10px); /* Further increase size for smaller screens */
    max-width: 65px; /* Further increase max-width */
    height: auto;
    margin-right: 10px;
  }

  .payment-method-icons {
    margin-left: 35px;
  }
}

@media (width: 430px) {
  .payment-method-icons img {
    width: calc(120% / 4 - 10px); /* Further increase size for smaller screens */
    max-width: 65px; /* Further increase max-width */
    height: auto;
    margin-right: 20px;
  }

  .payment-method-icons {
    margin-left: 35px;
  }
}

