.pagination {
  display: flex;
  align-items: center;
  justify-content: center; /* Aligns pagination to the center */
  margin-top: 12px;
  margin-bottom: 15px;
}

.page-button {
  width: auto;
  height: 30px;
  margin: 0 5px;
  padding: 0 10px;
  border-radius: 5px;
  border: none;
  background-color: #242424;
  color: #D7D7D7;
  cursor: pointer;
  transition: background-color 0.3s;
}

.page-button.active {
  background: linear-gradient(90deg, #c9f62e 0%, #96f53c 72%, #82f441 100%);
  color: linear-gradient(90deg, #c9f62e 0%, #96f53c 72%, #82f441 100%);
}

.page-button:hover {
  background: linear-gradient(90deg, #c9f62e 0%, #96f53c 72%, #82f441 100%);
}

.page-button:disabled {
  background-color: #444;
  cursor: not-allowed;
}
