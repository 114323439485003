.race-number-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks */
  width: 100%; /* Ensure it spans the full width */
  padding-left: 13px;
  padding-top: 8px;
}

.race-number-buttons {
  display: flex;
  flex-direction: row; /* Arrange buttons in a row */
  justify-content: flex-start;
  padding: 10px;
  margin: 0;
  gap: 10px; /* Space between buttons */
}

.race-number-button {
  height: 42px;
  width: 45px;
  border-radius: 50%; /* Circle shape */
  border: 1px solid #fff;
  background-color: #333;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center; /* Center text horizontally */
  align-items: center; /* Center text vertically */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  flex-shrink: 0; /* Prevent buttons from shrinking */
}

.race-number-button.active {
  background-color: #980E39; /* Active background color */
  border-color: #980E39;
}

.race-number-button.hovered {
  background-color: #980E39; /* Add hover background */
  color: #fff; /* Hover text color */
  border-color: #980E39; /* Hover border color */
}

.race-number-button:not(.hovered):not(.active) {
  background-color: #333; /* Default background color */
  color: #fff; /* Default text color */
  border-color: #fff; /* Default border color */
}


/* Adjustments for small screens */
@media (max-width: 768px) {
  .race-number-buttons {
    padding: 5px; /* Adjust padding */
  }

  .race-number-button {
    height: 40px;
    width: 40px;
    font-size: 12px; /* Smaller font size for mobile */
  }
}
