.race-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 10px;
  padding-right: 35px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  color: #fff;
}

/* Race Numbers Container */
.race-numbers-container {
  display: flex;
  overflow-x: auto; /* Allow horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping to the next line */
  background-color: #383838; /* Background color */
  padding: 50000px 0; /* Adjust padding to reduce gaps */
  justify-content: flex-start; /* Align items to the left */
}

.race-number span {
  font-size: 16px;
  font-weight: bold;
  line-height: 1; /* Ensures the text is vertically centered */
}

/* Horse Details */
.runner-details {
  flex: 1;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: -25px;
}

/* Race Position Styling */
.race-position {
  font-size: 16px;
  font-weight: bold;
  color: #FFD700;
}

/* Winner UI */
.winner-ui {
  font-size: 18px;
  color: #FFD700;
  text-align: center;
  margin-bottom: 20px;
}

/* Scrollbar Customization */
.race-numbers-container::-webkit-scrollbar {
  height: 8px;
}

.race-numbers-container::-webkit-scrollbar-thumb {
  background-color: #6b6b6b; /* Customize scrollbar color */
  border-radius: 10px;
}

.race-numbers-container::-webkit-scrollbar-track {
  background-color: #383838;
}

.horse-name {
  font-weight: bold;
  color: #A4C639;
  font-size: 14px;
  margin-left: 20px;
}

.race-number {
  width: 31px;
  height: 21px;
  border-radius: 0 20px 20px 0; /* Rounded corners on the right side */
  background-color: #000; /* Black background color */
  color: #fff; /* White text color */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px; /* Font size */
  font-weight: bold;
  position: absolute; /* Absolute positioning */
  top: 5px; /* Position from the top */
  left: 10px; /* Position from the left */
  margin-left: -5px;
}

.jockey-name, .trainer-name {
  color: #FFF;
  font-size: 14px;
  margin-left: 20px;
  margin-right: 10px;
}

/* src/components/RaceHorse/RaceHorse.css */
.runner-number-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-top: -45px;
  margin-right: 10px;
  margin-left: -10px;
}

.runner-number-detail {
  width: 45px;
  height: 30px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #161616;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}

.bet-buttons {
  display: flex;
  gap: 10px; /* Space between buttons */
  align-items: center; /* Align items vertically centered */
}

.button-background {
  display: flex; /* Ensure flex display for inline buttons */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  width: 150px; /* Adjust width to fit content */
  height: 60px; /* Set a consistent height */
  background-size: contain; /* Ensure the image fits within the container */
  background-position: center;
  background-repeat: no-repeat; /* Prevent the image from repeating */
  /* Add any specific styles for buttonBackground here */
}

.scratched-image {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.select-all-container {
  margin-left: 40px;
}

.position-image{
  margin-left: 20px;
  width: 70px;
  height: 70px;
}

@media screen and (max-width: 768px) {
  .jockey-name, .trainer-name, .horse-name {
    font-size: 14px;
  }
}