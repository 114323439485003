.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to bottom, #D6152C, #19004F);
  color: white;
  text-align: center;
}

.loading-logo img {
  margin-top: 20px;
  max-width: 500px;
  height: auto;
  margin-bottom: 20px;
}

.video-placeholder {
  margin: 20px 0;
  border: 5px solid #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 800px;
  position: relative;
  padding-top: 56.25%;
  z-index: 1;
}

.video-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  pointer-events: auto;
}

.progress-bar {
  width: 80%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  margin: 20px 0;
}

.progress {
  height: 20px;
  background: linear-gradient(to bottom, #FFE190, #C69914);
  border-radius: 10px;
  width: 0;
  animation: loadAnimation 3s forwards;
}

@keyframes loadAnimation {
  to {
    width: 100%;
  }
}

.loading-text {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.footer {
  position: absolute;
  bottom: 10px;
  font-size: 0.8em;
}

@media (min-width: 768px) {
  .loading-logo img {
    max-width: 150%;
  }

  .video-placeholder {
    width: 80%;
    max-width: 600px;
  }

  .progress-bar {
    width: 50%;
    border-radius: 18px;
  }

  .progress {
    height: 30px;
    border-radius: 17px;
  }
}

@media (min-width: 1024px) {
  .loading-logo img {
    max-width: 200%;
  }

  .video-placeholder {
    width: 60%;
    max-width: 800px;
    height: 450px;
    padding-top: 0;
  }

  .video-frame {
    height: 100%;
  }

  .progress-bar {
    width: 30%;
    border-radius: 20px;
  }

  .progress {
    height: 40px;
    border-radius: 20px;
  }
}
