/* General styling */
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto Flex', sans-serif;
    background-color: #d7d7d7;
    color: white;
}

.terms-condition-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 1000px;
    margin: 0 auto;
    background-color: #242424;
    color: white;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 1);
    position: relative;
}

.terms-condition-container {
    background-color: #242424;
    padding: 20px;
    border-radius: 10px;
    color: white;
    box-sizing: border-box;
    width: 100%;
}

.terms-label {
    font-family: 'Roboto Flex', sans-serif;
    font-weight: 300;
    margin-bottom: 10px;
    font-size: 18px;
    color: white;
}

.language-selector {
    margin-bottom: 20px;
}

.language-selector select {
    background-color: #393939;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-family: 'Roboto Flex', sans-serif;
    font-size: 16px;
    cursor: pointer;
    outline: none;
}

.language-label {
    color: #d7d7d7;
    font-family: 'Roboto Flex', sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
}

.language-selector select:focus {
    border: 1px solid #d7d7d7;
}

.rules-detail-container {
    background-color: #393939;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.terms-condition-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.terms-section {
    margin-bottom: 20px;
}

.terms-section h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.terms-section ul {
    list-style-type: none;
    padding-left: 0;
}

.terms-section li {
    margin-bottom: 10px;
    line-height: 1.5;
}

.terms-section p {
    margin-bottom: 10px;
    line-height: 1.5;
}

/* Mobile responsive */
@media (max-width: 768px) {

    .terms-label {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .terms-section h5 {
        font-size: 14px;
    }

    .terms-section li, .terms-section p {
        font-size: 14px;
    }

    .terms-condition-page {
        margin-top: 21px;
    }

    .terms-condition-container {
        flex-grow: 1;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 0px;
        margin-top: -21px;
    }
}

/* Tablet and larger screens */
@media (min-width: 1024px) {

    .terms-label {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 24px;
        color: #d7d7d7;
        text-align: center;
    }

    .terms-condition-page {
        margin-bottom: 25px;
        border-top: 12px solid #1b1b1b;
    }

    .terms-section h5 {
        font-size: 18px;
    }

    .terms-section li, .terms-section p {
        font-size: 16px;
    }
}

.terms-section ul{
    margin-left: 20px; /* Indent the content */
    text-align: justify;
}

.terms-section p {
    text-align: justify;
}

