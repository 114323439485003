@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@400;500;700&display=swap');

.how-to-bet-page {
  background-color: #242424;
  color: #fff;
  min-height: 100vh; /* Make the page take at least the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space out content (header, main, footer) */
  padding: 0; /* Remove padding to avoid constraining the width */
  width: 100%; /* Ensure the page fills the width of the viewport */
  font-family: 'Roboto Flex', sans-serif;
}

.page-header {
  display: flex;
  background-color: #383838;
  align-items: center;
  width: 100%; /* Fill the entire width of the page */
  height: 29px;
  padding: 20px; /* Optional padding to control the inner spacing */
  box-sizing: border-box; /* Ensure padding does not affect the width */
  margin-bottom: 20px;
}

.back-button {
    width: 30px; /* Adjust the size of the back icon */
    height: auto;
    margin-right: 8px; /* Add some space between the icon and the text */
    cursor: pointer;
}

.page-header h4 {
  font-family: 'Roboto Flex', sans-serif;
  margin-left: 10px;
  font-weight: 300;
  font-size: 18px;
  color: white;
}

.video-section {
  margin-top: 30px;
  padding: 0 20px; /* Add padding inside the content section */
  box-sizing: border-box; /* Ensure padding doesn't affect the width */
}

.video-section h2 {
  font-family: 'Roboto Flex', sans-serif;
  color: #BEF631;
  margin-bottom: 10px;
  font-weight: 100;
  font-size: 18px;
}

.video-card {
  display: flex;
  align-items: center;
  background-color: #444;
  padding: 15px;
  margin-bottom: 10px;
  width: 100%;
}

.video-thumbnail {
  position: relative;
  width: 150px; /* Fixed width */
  height: 100px; /* Fixed height */
  margin-right: 15px;
  background-color: #555;
  overflow: hidden; /* Ensures the image doesn't exceed the container */
}

.video-cover {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the whole container */
}

.video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 36px;
  color: #fff;
  pointer-events: none;
}

.video-info {
  flex-grow: 1;  /* Allow the video info to take up the remaining space */
}

.onboarding-section .video-info h3  {
  margin: 0;
  font-weight: 100;
  font-size: 10px;
  color: #a0f030;
  text-transform: uppercase;
}

.learn-bet-section .video-info h3  {
  margin: 0;
  font-weight: 100;
  font-size: 16px;
  color: #FFFFFF;
  text-transform: uppercase;
}

.video-info p {
  font-family: 'Roboto Flex', sans-serif;
  font-size: 10px;
  color: #FFFFFF;
}

.info-section {
  padding: 10px 20px;
  background-color: #393939;
  margin-top: 2px;
  margin-left: 18px;
  margin-right: 18px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.bet-rules {
  font-family: 'Roboto Flex', sans-serif;
  color: #BEF631; /* Green for section titles */
  font-size: 16px;
  font-weight: 100;
  margin-top: 40px;
  margin-left: 18px;
  margin-bottom: 10px;
}

.tg-support {
  font-family: 'Roboto Flex', sans-serif;
  font-weight: 100;
  color: #2AABEE; /* Green for section titles */
  font-size: 16px;
  margin-top: 30px;
  margin-left: 18px;
  margin-bottom: 10px;
}

.info-item {
  display: flex;
  align-items: center;
  background-color: #383838;
  padding: 10px;
  border-radius: 8px;
  justify-content: space-between;
}

.info-item img {
  width: 24px;
  height: 24px;
}

.info-item p {
  font-family: 'Roboto Flex', sans-serif;
  font-size: 14px;
  color: #FFFFFF;
  flex-grow: 1;
  margin-left: 10px;
}

.arrow-icon {
  width: 16px;
  height: 16px;
}

.guide-header h1 {
  background-color: #242424;
  margin-top: 40px;
  font-family: 'Roboto', sans-serif;
  color: #d7d7d7; 
  text-align: center;
  font-size: 3rem; 
  text-transform: uppercase; 
  letter-spacing: 2px;
  font-weight: 700; 
  animation: fadeIn 1s ease-in-out; 
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* Slide-up effect for the sub-heading */
@keyframes slideUp {
  0% { transform: translateY(20px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

.guide-header h2 {
  font-family: 'Roboto', sans-serif; 
  text-align: center;
  margin-bottom: 20px;
  color: #BEF631; 
  font-size: 2rem; 
  letter-spacing: 1px;
  /* margin: 20px 0 0;  */
  font-weight: 400;
  text-transform: uppercase; 
  animation: slideUp 1.2s ease-in-out; 
}

@media (min-width: 1024px){
  .video-card {
    display: flex;
    align-items: center;
    background-color: #444;
    padding: 15px;
    margin-bottom: 10px;
    width: 1000px;
  }

  .info-section {
    width: 1000px;
  }

  .how-to-bet-page{
    align-items: center;
  }

  .onboarding-section .video-info h3  {
    font-size: 16px;
}

  .info-item p {
    font-size: 17px;
  }

  .video-info p {
    font-size: 12px;
  }
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .video-card {
    flex-direction: row;
    align-items: center;
    background-color: #242424;
    justify-content: flex-start;
    width: 100%; /* Ensure full width on mobile */
  }

  .how-to-bet-page {
    margin-top: -20px;
  }

  .video-thumbnail {
    width: 100%;
    max-width: 150px;
    margin-right: 15px;
  }

  .video-info h3 {
    font-family: 'Roboto Flex', sans-serif;
    font-size: 14px;
  }

  .video-info p {
    font-size: 12px;
  }

  .info-section {
    margin-left: 27px;
  }
  
  .bet-rules {
    margin-left: 27px;
  }
  
  .tg-support {
    margin-left: 27px;
  }
}
