@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@400;500;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Flex', sans-serif;
  background-color: #D7D7D7;
  color: white;
}

.account-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.account-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1000px;
  margin: 0 auto;
  background-color: #242424;
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 1);
  width: 100%;
  position: relative;
}

/* Background image container */
.account-background-container {
  position: relative;
  width: 100%;
  height: 430px; /* Set a standard height for mobile */
  overflow: hidden;
}

.account-background {
  width: 100%;
  height: 110%;
  object-fit: cover; /* Ensure the image fits the container without distortion */
  position: relative;
  filter: contrast(120%) brightness(100%);
  z-index: 0; /* Keep the image behind the overlay */
}

.account-background-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #B71232, #420548);
  opacity: 0.1;
  z-index: 1; /* Overlay z-index */
}

.account-header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the header */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Ensures the header is above the overlay */
  text-align: center;
  color: white;
}

.account-avatar {
  width: 100px;
  height: 100px;
  background-color: #444;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
  margin: 0 auto 10px auto;
}

.account-info {
  text-align: center;
}

.account-info .account-name {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}

.account-info .account-phone {
  margin: 5px 0 0 0;
  font-size: 16px;
  color: #ccc;
}

.account-options {
  margin-top: 40px;
}

.account-option {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures even spacing */
  background-color: #393939;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative; /* Make sure child elements can be positioned inside */
}

.account-option img {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.account-option span {
  flex-grow: 1;
  font-size: 16px;
}

.account-option .arrow {
  font-size: 30px;
  color: #ccc;
  position: absolute;
  right: 15px; /* Fixed distance from the right edge */
  top: 50%;
  transform: translateY(-50%); /* Vertically center the arrow */
}

.account-option.sign-out {
  background-color: #980E39;
  color: white;
  text-align: center;
}

.account-option.sign-out img {
  width: 40px;
  height: 40px;

}

.how-to-bet-container{
  background-color: #242424;
}
/* Mobile view */
@media (max-width: 768px) {
  .account-page {
    max-width: 100%; /* Ensure the page takes the full width on smaller screens */
    padding: 1px;
    border-radius: 0;
    margin-top: -4px;
    max-width: 800px;
  }

  .account-background-container {
    height: 200px; /* Reduce the background height for smaller screens */
  }

  .account-avatar {
    width: 80px;
    height: 80px;
    font-size: 28px;
  }

  .account-info .account-name {
    font-size: 20px;
  }

  .account-info .account-phone {
    font-size: 14px;
  }

  .account-option{
    margin-left: 8px;
    margin-right: 8px;
  }

  .account-option img {
    width: 20px;
    height: 20px;
  }

  .account-option span {
    font-size: 14px;
  }

  .account-option .arrow {
    font-size: 24px;
  }
}

@media (min-width: 1024px) {
  .account-page {
    max-width: 1200px;
    padding: 40px;
  }

  .account-avatar {
    width: 120px;
    height: 120px;
    font-size: 48px;
  }

  .account-info .account-name {
    font-size: 28px;
  }

  .account-info .account-phone {
    font-size: 18px;
  }

  .account-option img {
    width: 28px;
    height: 28px;
  }

  .account-option span {
    font-size: 18px;
  }
}
