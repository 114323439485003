.race-result-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  background-color: #242424;
  padding-left: 15px;
  width: 100%; /* Ensure the wrapper takes full width */
}

.race-buttons-container {
  display: flex;
  gap: 10px;
  margin-top: 12px;
  margin-bottom: 12px;
  justify-content: flex-start; /* Align buttons to the left */
  width: 100%; /* Ensure the container takes full width */
  padding: 0 15px; /* Add padding to align with other components */
  background-color: transparent; /* Remove background color */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks */
}

.race-button {
  background-color: #333;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50px;
  padding: 7px 13px; /* Adjust padding for larger buttons */
  font-size: 16px; /* Adjust font size */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 10px;
}

/* Hover effect for buttons */
.race-button.hovered {
  background: #980E39; /* Hover background color */
  color: #FFFFFF; /* Hover text color */
  border: 2px solid #980E39; /* Hover border color */
}

/* Remove hover effect when not hovered or active */
.race-button:not(.hovered):not(.active) {
  background-color: #333; /* Default background color */
  color: #fff; /* Default text color */
  border-color: #fff; /* Default border color */
}

.race-button.active {
  background-color: #980E39;
  color: #fff;
  border-color: #980E39;
}

/* Responsive design for mobile view */
@media (max-width: 768px) {
  .race-buttons-container {
    flex-wrap: nowrap; /* Prevent buttons from wrapping to the next line */
    justify-content: flex-start; /* Align buttons to the left */
    padding: 0; /* Remove padding for mobile view */
  }

  .race-result-wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }

  .race-button {
    padding: 5px 13px; /* Adjust padding for smaller buttons */
    font-size: 14px; /* Adjust font size for smaller buttons */
  }
}
