.dynamic-race-display {
  width: 100%;
  background: linear-gradient(135deg, #FFEF62, #BDFFD6); /* Example color */
  color: #000000;
  font-size: 20px;
  font-weight: bolder;
  padding: 10px;
  border-radius: 0px;
  margin-top: 10px;
  overflow: hidden; /* Ensures content outside the container is hidden */
  position: relative;
}

.animated-text {
  display: inline-block;
  white-space: nowrap; /* Prevents text from wrapping */
  animation: moveText 15s linear infinite; /* Controls animation speed and duration */
  word-spacing: 5px; /* Adds spacing between words */
}

@keyframes moveText {
  0% {
    transform: translateX(100%); /* Start outside the right boundary */
  }
  50% {
    transform: translateX(0); /* Center and fully visible */
  }
  100% {
    transform: translateX(-100%); /* Move completely outside the left boundary */
  }
}

.underline {
  text-decoration: underline;
  text-decoration-color: #1C7600; /* Underline color */
  text-decoration-thickness: 2px; /* Adjust thickness if needed */
}

.green-text {
  color: #217504;
  font-size: 22px;
}

.highlight {
  color: #d32f2f; /* Example color for "BET NA!" */
  font-weight: bold;
  font-size: 22px;
}
