/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll; /* Enable scrolling if content overflows */
}

/* Header Section */
.header {
  background-color: #242424;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.url-bar {
  font-size: 12px;
}

.balance-section {
  display: flex;
  align-items: center;
}

.balance {
  background-color: #A4C639;
  color: black;
  padding: 5px 10px;
  margin-right: 10px;
  font-weight: bold;
}

/* Race Image Section */
.race-image-section {
  background-color: #B61233;
  padding: 15px;
  text-align: center;
  color: white;
}

/* Race Categories */
.race-categories {
  display: flex;
  justify-content: space-around;
  background-color: #242424;
  padding: 10px 0;
}

/* Race List */
.race-list {
  background-color: #333;
  color: white;
  padding: 10px;
}

.race-tab {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.race-tab button {
  background-color: #B61233;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.race-item {
  display: flex;
  justify-content: flex;
  background-color: #444;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.horse-details {
  text-align: left;
}

.race-position {
  font-weight: bold;
}

.open-bet-button{
  width: 150px;
  font-family: 'Roboto Flex', sans-serif;
  height: 23px;  
  font-weight: bold;
  margin-left: 25px;
  border-color: transparent;
  border-radius: 20px;
  margin-bottom: 50px;
  background: linear-gradient(135deg, #FFF61F, #82F441);
}

.bet-button-container{
  margin-top: -70px;
  background-color: #242424;
  padding-bottom: 20px;
}

.race-page{
  background-color: #242424;
}

.poster-popup-overlay {
  z-index: 10000;  /* Ensure it's on the topmost layer */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);  /* Optional: Add a dim background effect */
}

.poster-popup-content {
  width: 800px;
  height: 900px;
  position: relative;
  border-radius: 12px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.poster-popup-content img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 10;
}

.content-layer {
  position: relative;
  z-index: 1;
}

.race-page{
  overflow: visible;
  position: relative;
}

@media (min-width: 1024px) {
  .race-page-container{
    overflow: visible;
    flex: 1;
    display: flex;
    z-index: 1;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 1300px;
    margin: 0 auto;
    background-color: #242424;
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 1);
    width: 100%;
    position: relative;
  }

  .race-video{
    height: 500px;
  }

  .race-bet-types{
    margin-top: 1px;
  }
}

@media (width: 768px){
  .race-bet-types{
    margin-top: 1px;
  }
}

.poster-popup-content button {
  position: absolute;
  top: 170px;
  left: 0;
  margin: 8px;
  font-size: 24px;
  font-weight:bolder;
  background: white;
  border: none;
  color: black;
  z-index: 20;
  cursor: pointer;
  padding: 5px;
}

@media (max-width: 768px) {
  .poster-popup-content {
    width: 90%;
    top: 100px;
    height: auto;
    max-height: 80%;
    padding: 10px;
  }

  .poster-popup-content img {
    width: 100%;
    height: auto;
  }

  .poster-popup-overlay {
    align-items: flex-start; /* Optional: move the popup slightly down on mobile */
  }

  /* Close button adjustment */
  .poster-popup-content button {
    font-size: 18px;
    top: 5px;
    left: 5px;
  }
}
