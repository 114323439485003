/* General styling */
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto Flex', sans-serif;
    background-color: #d7d7d7;
    color: white;
}

.label {
    font-family: 'Roboto Flex', sans-serif;
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: 300;
    margin-left: 20px;
    font-size: 1.125rem; /* Responsive font size */
    color: white;
}

.about-page-container {
    background-color: #242424;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}

.about-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%; /* Full width */
    margin: 20px auto 40px auto; 
    background-color: #242424;
    color: white;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 1); /* Almost fully opaque */
    box-sizing: border-box;
    padding: 20px;
}

/* Background image container */
.about-background-container {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 500px;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.about-background {
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
    position: relative;
    filter: contrast(150%) brightness(200%);
    z-index: 0;
}

.karrera-king-logo, .hari-ng-karera-logo {
    position: absolute;
    left: 50%;
    width: 35%; /* Responsive size based on container */
    z-index: 2;
}

.karrera-king-logo {
    top: 30%;
    left: 10%;
}

.hari-ng-karera-logo {
    top: 20%;
    right: 5%;
}

.about-background-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #420548e7, #B61233);
    opacity: 0.8;
    z-index: 1;
}

.about-header {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    text-align: center;
    color: white;
}

/* Content section */
.about-content {
    margin-top: 20px;
    padding: 0 15px;
}

.about-content p {
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 1rem;
    line-height: 1.5;
}

/* Icons container */
.about-icons-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px 0;
}

.about-icons-container .icon {
    margin-top: 100px;
    text-align: center;
    color: white;
    flex: 1 1 100px;
    max-width: 120px;
}

.about-icons-container .icon img {
    width: 80;
    height: 80px;
    margin-bottom: 10px;
}

.about-icons-container .icon p {
    margin-top: 10px;
    font-size: 1rem;
}

/* Details container */
.about-details-container {
    background-color: #393939;
    min-height: 100vh; /* Flexible minimum height */
}

.desktop-header h1 {
    margin-top: 40px;
    font-family: 'Roboto', sans-serif;
    color: #d7d7d7; 
    text-align: center;
    font-size: 3rem; 
    text-transform: uppercase; 
    letter-spacing: 2px;
    font-weight: 700; 
    animation: fadeIn 1s ease-in-out; 
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

/* Slide-up effect for the sub-heading */
@keyframes slideUp {
    0% { transform: translateY(20px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
}

.desktop-header h2 {
    font-family: 'Roboto', sans-serif; 
    text-align: center;
    margin-bottom: 20px;
    color: #BEF631; 
    font-size: 2rem; 
    letter-spacing: 1px;
    /* margin: 20px 0 0;  */
    font-weight: 400;
    text-transform: uppercase; 
    animation: slideUp 1.2s ease-in-out; 
}

@media (max-width: 768px) {
    .about-details-container {
        background-color: #393939;
        margin-bottom: 100px;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 15px 15px 15px 15px;
        min-height: 100vh; /* Flexible minimum height */
    }

    .about-icons-container .icon img {
        width: 50;
        height: 50px;
        margin-bottom: 10px;
    }

}

/* Tablet and larger screens */
@media (min-width: 1024px) {
    .label {
        font-family: 'Roboto Flex', sans-serif;
        font-weight: bold;
        text-align: center;
        font-size: 80px; /* Responsive font size */
        color: #d7d7d7;
    }

    .about-content p {
        margin-left: 20px;
        text-align: center;
        margin-right: 20px;
        margin-bottom: 20px;
        font-size: 25px;
        line-height: 1.5;
    }

    .about-details-container {
        background-color: #242424;
    }

    .about-background-container{
        border-radius: 0px 0px 0px 0px;
        border-top: 4px solid #BEF631;
        border-bottom: 4px solid #BEF631;

    }
}
