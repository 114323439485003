/* Main Login Section Container */
.login-section {
  padding: 10px;
  border-radius: 10px;
  margin: 20px auto; /* Centered on the page */
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

/* Tabs Container */
.login-register-tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  border-bottom: 2px solid #333;
  width: 90%;
}

.warning-message {
  text-align: left;
  margin-left: 5px;
}

/* Recaptcha  */
.recaptcha-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0; /* Adjust spacing as needed */
}

/* Individual Tab */
.tab {
  padding: 6px 0;
  font-size: 14px; /* Smaller font size */
  color: #fff;
  cursor: pointer;
  position: relative;
  flex: 1;
  background-color: transparent;
}

/* Active Tab Styling */
.tab.active {
  color: #a4c639; /* Highlight color for active tab */
  background-color: transparent;
}

/* Tab Underline */
.tab.active::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #a4c639; /* Underline color */
}
.form-container {
  width: 95%;
}
.login-form {
  width: 100%;
}
.register-form {
  width: 100%;
}

.login-form,
.register-form {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

/* Input Fields */
.input-field {
  width: 100%;
  padding: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 25px;
  border: 2px solid #fff;
  background-color: #000;
  color: #fff;
  font-size: 12px;
}

.input-field::placeholder {
  color: #fff;
  padding-left: 10px;
}

/* Forgot Password Link */
.forgot-password {
  display: block;
  margin-top: 8px;
  color: #fff;
  text-decoration: none;
  font-size: 12px; /* Smaller font size */
}

.forgot-password:hover {
  text-decoration: underline;
}

.agent-image {
  width: 100%;
  border-radius: 10px;
}

/* Footer Text */
.footer-text {
  margin-top: 8px;
  color: #9b9999;
  font-size: 15px; /* Smaller font size */
}
