.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
.popup-container {
  background: linear-gradient(135deg, #7928ca, #ff0080);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  color: white;
  width: 250px;
}

.popup-icon img {
  width: 80px;
  margin-bottom: 10px;
}

.popup-button {
  margin-top: 20px;
  padding: 10px;
  background: linear-gradient(135deg, #420548, #B61233);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.popup-note {
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 10px;
}

.error-note {
  font-size: 16px;
  margin-top: 25px;
  margin-bottom: 50px;
}

@media (min-width: 1024px){
  .popup-container{
    width: 300px;
    height: 340px;
  }
}
